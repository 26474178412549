import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import BannerSlice from "./slice/BannerSlice";
import CategorySlice from "./slice/CategorySlice";
import BrandSlice from "./slice/BrandSlice";
import FavoriteSlice from "./slice/FavoriteSlice";
import UserSlice from "./slice/UserSlice";
import CartSlice from "./slice/CartSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  banner: BannerSlice,
  category: CategorySlice,
  brand: BrandSlice,
  favorite: FavoriteSlice,
  user: UserSlice,
  cart: CartSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
