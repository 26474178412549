import { Avatar } from "@mui/material";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import LogoutIcon from "@mui/icons-material/Logout";
import "./UserInformation.scss";
import { Link } from "react-router-dom";
import { logoutService } from "~/services/LoginService";
import { useDispatch, useSelector } from "react-redux";

function UserInformation() {
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.user;
  });

  const handleLogout = () => {
    logoutService(dispatch);
  };
  return (
    <div className="user">
      <Avatar
        className="user-avt"
        alt="avt"
        src={user.userInfo ? user.userInfo.picture : "/static/images/avatar/1.jpg"}
      />
      <ul className="list-user-contents">
        <li className="user-contents-items">
          <Link to={`profile`}>
            <PersonOutlineIcon className="icon-user" />
            Quản Lý Tài Khoản
          </Link>
        </li>
        <li className="user-contents-items">
          <Link to={`order`}>
            <LocalMallOutlinedIcon className="icon-user" />
            Đơn Mua
          </Link>
        </li>
        <li className="user-contents-items">
          <Link to={`review`}>
            <StarBorderIcon className="icon-user" />
            Đánh Giá Sản Phẩm
          </Link>
        </li>
        <li className="user-contents-items">
          <div onClick={handleLogout}>
            <LogoutIcon className="icon-user" />
            Đăng Xuất
          </div>
        </li>
      </ul>
    </div>
  );
}

export default UserInformation;
