import axios from "axios";
import ApiUrl from "../utils/RewriteApi";

export const getCategoryMapBrand = async () => {
  try {
    const res = await axios.get(ApiUrl("/category-map-brand/get"));
    return res.data.categoryMapBrand;
  } catch (error) {
    console.log(error);
  }
};
