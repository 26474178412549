import React from "react";
import { Link } from "react-router-dom";

function ListNavbar() {
  return (
    <ul className="list-menu d-flex justifty-content-center align-items-center">
      <li className="item">
        <Link to="/">Trang Chủ</Link>
      </li>
      <li className="item">
        <Link to="/contact">Liên Hệ</Link>
      </li>
      <li className="item">
        <Link to="/about">Thông Tin</Link>
      </li>
    </ul>
  );
}

export default ListNavbar;
