function formatNumber(number) {
  const numberStr = number.toString();
  const parts = numberStr.split(".");
  const integerPart = parts[0];
  const decimalPart = parts[1] || "";

  let formattedIntegerPart = "";
  for (let i = 0; i < integerPart.length; i++) {
    formattedIntegerPart += integerPart[i];
    if ((integerPart.length - i - 1) % 3 === 0 && i !== integerPart.length - 1) {
      formattedIntegerPart += ",";
    }
  }

  const formattedNumber = formattedIntegerPart + (decimalPart ? "." + decimalPart : "");
  return formattedNumber;
}

module.exports = formatNumber;
