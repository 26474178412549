import React from "react";
import "./MyReviewProduct.scss";
import ChoosePhotoVideoRating from "../choosePhotoVideoRating/ChoosePhotoVideoRating";
import RatingHoverText from "../ratingHoverText/RatingHoverText";

function MyReviewProduct() {
  return (
    <div className="my-review-product">
      <div className="product-my-review">
        <div className="img-product-review">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLWAHRDHEjkepX9IevbplSo5fWo9bHdDADWq-UnE6fjN77jv-4HCCAfFapaOF-uUJRNA&usqp=CAU"
            alt=""
          />
        </div>
        <div className="information-product-review">
          <h5 className="name-product-review">
            Tắm gội 2 trong 1 Romano 2in1 650g + Dầu gội sạch gàu 150g Romano
          </h5>
          <div className="quantity-product-review">
            X<span>1</span>
          </div>
        </div>
      </div>
      <div className="rating-product">
        <span>Chất lượng sản phẩm: </span>
        <RatingHoverText />
      </div>
      <div className="form-review-product">
        <div className="review-description">
          Đánh giá sản phẩm
          <textarea
            name=""
            id=""
            cols="30"
            rows="6"
            placeholder="Hãy chia sẽ những cảm nhận về sản phẩm của bạn cới những người mua khác nhé..."
          ></textarea>
        </div>
        <div className="img-review-product">
          <ChoosePhotoVideoRating />
        </div>
      </div>
    </div>
  );
}

export default MyReviewProduct;
