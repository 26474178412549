import React from "react";
import OrderItem from "~/components/miniComponent/orderItem/OrderItem";

function ReturnRefund() {
  return (
    <div>
      <OrderItem />
    </div>
  );
}

export default ReturnRefund;
