import React, { useEffect, useState } from "react";
import Breadcrumb from "~/components/breadcrumb/Breadcrumb";
import "./Cart.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import QuatityButton from "~/components/miniComponent/quatityButton/QuatityButton";
import { useDispatch, useSelector } from "react-redux";
import { getProductsByIds } from "~/services/ProductService";
import { changeCart, removeFromCart } from "~/redux/slice/CartSlice";
import formatNumber from "~/utils/FormatNumber";
import PaymentDiaLog from "~/components/miniComponent/paymentDiaLog/PaymentDiaLog";
import cartEmpty from "~/assets/images/cart-empty.png";
import { getDiscountByCode } from "~/services/DiscountServive";

function Cart() {
  const cartList = useSelector((state) => {
    return state.cart;
  });
  const dispatch = useDispatch();
  const [listCart, setListCart] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [productIdToDelete, setProductIdToDelete] = useState(null);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [discount, setDiscount] = useState({ discount: "", priceMinus: 0 });
  const [discountError, setDiscountError] = useState("");

  useEffect(() => {
    setShipping(0);
    const fetchData = async () => {
      const res =
        cartList.length !== 0
          ? await getProductsByIds(
              cartList.map((item) => {
                return item.product_id;
              }),
            )
          : false;
      if (res) {
        const cartMap = new Map(cartList.map((item) => [item.product_id, item.quantity]));
        setListCart(
          res.products.map((product) => ({
            ...product,
            quantity: cartMap.get(product._id) || 0, // Số lượng từ cart hoặc 0 nếu không có trong cart
          })),
        );
      }
    };
    fetchData();
  }, [cartList]);

  const handleOpenDialog = (productId) => {
    setProductIdToDelete(productId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    if (productIdToDelete !== null) {
      dispatch(changeCart({ product_id: productIdToDelete, quantity: 1 }));
    }
    setOpenDialog(false);
  };

  const handleDeleteProduct = () => {
    if (productIdToDelete !== null) {
      dispatch(removeFromCart(productIdToDelete));
    }
    setOpenDialog(false);
  };
  const handChangeQuantity = (id, newQuantity) => {
    // Giới hạn giá trị mới nằm trong khoảng từ 1 đến 99
    newQuantity = Math.min(Math.max(0, newQuantity), 99);

    // Nếu giá trị mới là 0, hiện hộp thoại xác nhận xoá sản phẩm
    if (newQuantity === 0) {
      handleOpenDialog(id);
    } else {
      const product = {
        product_id: id,
        quantity: newQuantity,
      };
      dispatch(changeCart(product));
    }
  };
  const handleBlur = (id, quantity) => {
    if (isNaN(quantity)) {
      handleOpenDialog(id);
    }
  };

  // #fixed-buy-now  chuyển xuống nơi thanh toán tiền
  const handleScrollToCartTotal = () => {
    const rowElement = document.querySelector(".row");
    if (rowElement) {
      const offset = 6 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      const scrollPosition = rowElement.offsetTop - offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handlePaymentClose = () => {
    setPaymentDialog(false);
  };

  const handlePaymentSubmit = () => {
    setPaymentDialog(false);
  };

  const handleChangeDiscount = (e) => {
    let value = e.target.value.toUpperCase();
    value = value.replace(/[^A-Z0-9]/g, "");
    setDiscount({ discount: value, priceMinus: 0 });
  };

  const handleClickAddDiscount = async () => {
    setDiscountError("");
    if (!discount.discount) {
      setDiscountError("Vui lòng nhập mã giảm giá!");
    } else {
      const res = await getDiscountByCode(
        discount.discount,
        cartList.map((item) => {
          return item.product_id;
        }),
      );
      if (res.success) {
        setDiscount(res.discount);
      } else {
        setDiscountError(res.message);
      }
    }
  };

  return (
    <div>
      <Breadcrumb />
      {listCart.length !== 0 ? (
        <>
          <a href="#fixed-buy-now" className="fixed-buy-now" onClick={handleScrollToCartTotal}>
            <i className="bi bi-clipboard-check"></i>
          </a>
          <table className="table-cart">
            <thead>
              <tr className="table-heading">
                <th className="product" width="40%">
                  Sản Phẩm
                </th>
                <th className="price" width="15%">
                  Đơn Giá
                </th>
                <th className="quantity" width="15%">
                  Số Lượng
                </th>
                <th className="Subtotal" width="15%">
                  Thành tiền
                </th>
                <th className="delete-button" width="15%">
                  Xoá Sản Phẩm
                </th>
              </tr>
            </thead>
            <tbody>
              {listCart.map((item, index) => {
                return (
                  <tr className="table-content " key={index}>
                    <td className="content-product">
                      <div className="img-product">
                        <img src={item.image} alt="" />
                      </div>
                      <h5 className="title-product">{item.productName}</h5>
                    </td>
                    <td className="content-price">
                      <span>{`$ ${formatNumber(item.currentPrice)}`}</span>
                      <span className="price-mobile">/sản phẩm</span>
                    </td>
                    <td className="content-quantity">
                      <QuatityButton
                        itemId={item._id}
                        quantity={item.quantity}
                        handChangeQuantity={handChangeQuantity}
                        handleBlur={handleBlur}
                      />
                    </td>
                    <td className="content-subtotal">
                      <span>{`$ ${formatNumber(item.currentPrice * item.quantity)}`}</span>
                    </td>
                    <td className="content-delete">
                      <Button
                        variant="contained"
                        color="redColor"
                        onClick={(e) => handleOpenDialog(item._id)}
                      >
                        <i className="bi bi-trash3"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* Thông báo sản phẩm delete */}
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Xác nhận xoá sản phẩm</DialogTitle>
            <DialogContent>
              <DialogContentText>Bạn có chắc muốn xoá sản phẩm này không?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="redColor" onClick={handleCloseDialog}>
                Không
              </Button>
              <Button variant="outlined" color="redColor" onClick={handleDeleteProduct}>
                Có
              </Button>
            </DialogActions>
          </Dialog>
          <PaymentDiaLog
            open={paymentDialog}
            onClose={handlePaymentClose}
            onSubmit={handlePaymentSubmit}
          />
          <div className="row" id="row-bottom">
            <div className="col-md-12 col-lg-6">
              <div className="coupon-code">
                <input
                  type="text"
                  value={discount.discount}
                  placeholder="Nhập Mã Giảm Giá"
                  onChange={handleChangeDiscount}
                />
                <button
                  className="cus-btn button-container apply-coupon"
                  onClick={handleClickAddDiscount}
                >
                  Áp Dụng Ngay
                </button>
              </div>
              {discountError && <div style={{ color: "red" }}>{discountError}</div>}
            </div>
            <div className="col-md-12 col-lg-6">
              <div id="cart-total" className="cart-total">
                <h5 className="title-total">Tổng Tiền</h5>
                <table className="table-total" width="100%">
                  <tbody>
                    <tr>
                      <td>Thành Tiền</td>
                      <td>
                        {`$ ${formatNumber(
                          listCart.reduce(
                            (total, item) => total + item.currentPrice * item.quantity,
                            0,
                          ),
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Phí Vận Chuyển</td>
                      <td>{shipping === 0 ? "Miễn Phí" : `$ ${shipping}`}</td>
                    </tr>
                    <tr>
                      <td>Mã giảm giá</td>
                      <td>
                        {discount.priceMinus === 0
                          ? "Không có"
                          : `- ${formatNumber(discount.priceMinus)}`}
                      </td>
                    </tr>
                    <tr>
                      <td>Tổng Tiền</td>
                      <td>
                        {`$ ${formatNumber(
                          listCart.reduce(
                            (total, item) => total + item.currentPrice * item.quantity,
                            0,
                          ) +
                            shipping -
                            discount.priceMinus,
                        )}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  className="cus-btn button-container btn-buy"
                  onClick={() => setPaymentDialog(true)}
                >
                  Đặt Mua
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="img-cart-empty">
          <img src={cartEmpty} alt="" />
        </div>
      )}
    </div>
  );
}

export default Cart;
