import React, { useState } from "react";
import "./SearchForm.scss";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

function SearchForm(props) {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [isInputVisible, setInputVisible] = useState(false);

  const searchOnSubmit = (e) => {
    e.preventDefault();
    if (searchInput) {
      navigate(`/category/&search=${searchInput}`);
    } else {
      navigate("/category/");
    }
  };

  const searchOnChange = (value) => {
    setSearchInput(value);
  };

  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const handleBgSearchClick = () => {
    setInputVisible(false);
  };

  return (
    <div className="search">
      <div
        className={`bg-search ${isInputVisible ? "show" : ""}`}
        onClick={handleBgSearchClick}
      ></div>
      <form className="form-search" action="/" onSubmit={(e) => searchOnSubmit(e)}>
        <div className={`input-search ${isInputVisible ? "show" : ""}`}>
          <input
            type="text"
            name=""
            id=""
            placeholder={props.placeholder}
            value={searchInput}
            onChange={(e) => searchOnChange(e.target.value)}
            className="input-text"
          />
          <button className="btn-search-next">
            <LogoutIcon className="search-icon" />
          </button>
        </div>
        <button type="submit" className="btn-submit-search" onClick={toggleInputVisibility}>
          <i className="bi bi-search"></i>
        </button>
      </form>
    </div>
  );
}

export default SearchForm;
