import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "~/views/home/Home";
import Login from "~/views/auth/Login";
import Contact from "~/views/contact/Contact";
import About from "~/views/about/About";
import WishList from "~/views/wishlist/WishList";
import Cart from "~/views/cart/Cart";
import PageNotFound from "~/views/pageNotFound/PageNotFound";
import ProductDetail from "~/views/product/ProductDetail";
import Category from "~/views/category/Category";
import Profile from "~/views/profile/Profile";
import Order from "~/views/order/Order";
import MyReviews from "~/components/myReviews/MyReviews";

export default function AppRouter() {
  return (
    <div className="main_content">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login isLogin={true} />} />
        <Route path="/register" element={<Login isLogin={false} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/product/:slug" element={<ProductDetail />} />
        <Route path="/product" element={<Navigate to="/category" replace />} />
        <Route path="/category" element={<Category isAll={true} />} />
        <Route path="/category/:slug" element={<Category />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/order" element={<Order />} />
        <Route path="/review" element={<MyReviews />} />
        <Route path="/flash-sale" element={<MyReviews />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}
