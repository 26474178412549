import React, { useEffect, useState } from "react";
import "./BackToTop.scss";
function BackToTop() {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    // Lắng nghe sự kiện cuộn trang
    const handleScroll = () => {
      // Tính toán chiều cao của toàn bộ trang và chiều cao của cửa sổ hiện tại
      const windowHeight = window.innerHeight;
      const fullHeight = document.documentElement.scrollHeight;

      // Tính toán vị trí cuộn hiện tại
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      // Cập nhật vị trí cuộn vào biến trạng thái

      // Tính toán mức cuộn tới 40% chiều cao của toàn bộ trang
      const fortyPercentHeight = (fullHeight - windowHeight) * 0.6;

      // Kiểm tra và hiển thị nút "back to top" khi cuộn xuống dưới 40% trang
      setShowBackToTop(currentScrollPosition >= fortyPercentHeight);
    };

    // Đăng ký sự kiện cuộn trang khi component được mount
    window.addEventListener("scroll", handleScroll);

    // Hủy đăng ký sự kiện khi component bị unmount để tránh memory leak
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // Cuộn trang lên đầu
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    // Cuộn trang xuống dưới cùng
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div
        className="back-to-top"
        onClick={showBackToTop ? scrollToTop : scrollToBottom}
      >
        {showBackToTop ? (
          <i className="bi bi-chevron-double-up"></i>
        ) : (
          <i className="bi bi-chevron-double-down"></i>
        )}
      </div>
    </div>
  );
}

export default BackToTop;
