import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import LoginWithGoogle from "~/components/auth/LoginForm/LoginWithGoogle";
import avatar from "~/assets/images/avatar/avatar.png";
import { Link } from "react-router-dom";
import { setAssetToken } from "~/redux/slice/UserSlice";
import setAuthToken from "~/utils/setAuthToken";
import { loadUser } from "~/services/LoginService";
import { useDispatch } from "react-redux";

function SignUpForm(props) {
  const dispatch = useDispatch();
  const [account, setAccount] = useState({ email: "", password: "" });

  const handleRegisterSubmit = (event) => {
    event.preventDefault();
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, account.email, account.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch(setAssetToken(user.accessToken));
        setAuthToken(user.accessToken);
        loadUser(dispatch);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log({ code: errorCode, mess: errorMessage });
      });
  };

  const onChangeRegisterForm = (event) => {
    setAccount((prevAccount) => ({ ...prevAccount, [event.target.name]: event.target.value }));
  };
  return (
    <>
      <Container component="div" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar src={avatar} sx={{ m: 1, width: 64, height: 64 }}></Avatar>

          <Typography component="h1" variant="h3">
            Đăng Ký
          </Typography>
          <Box component="form" onSubmit={handleRegisterSubmit} noValidate sx={{ mt: 1, mb: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              variant="standard"
              color="redColor"
              onChange={onChangeRegisterForm}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="standard"
              color="redColor"
              onChange={onChangeRegisterForm}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="repassword"
              label="Nhập lại mật khẩu"
              type="password"
              id="repassword"
              variant="standard"
              autoComplete="current-password"
              color="redColor"
              onChange={onChangeRegisterForm}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="redColor"
            >
              Đăng Ký Ngay
            </Button>
            <Box component="div" className="list-button" sx={{ mt: 2, mb: 2 }}>
              <LoginWithGoogle />
            </Box>
            <Grid container>
              <Grid item xs>
                <Link to="#" variant="body2">
                  Quên mật khẩu?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/login" variant="body2">
                  {"Đã có tài khoản? Đăng nhập"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default SignUpForm;
