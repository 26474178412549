import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import location from "~/configs/locationConfig.json";
import "./AddressDialog.scss";

function AddressDialog({ item, open, onClose, onSubmit }) {
  const [address, setAddress] = useState();
  const [district, setDistrict] = useState();
  const [province, setProvince] = useState();

  const handClose = () => {
    onClose();
  };
  const handSubmit = () => {
    onSubmit();
  };
  console.log(location);
  return (
    <Dialog
      className="address-dialog"
      open={open}
      keepMounted
      onClose={handClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{`${item ? "Thay đổi" : "Thêm"} địa chỉ`}</DialogTitle>
      <DialogContent>
        <div className="item-edit-address">
          <div className="d-flex align-items-center name-dialog">
            <span>Tên</span>
            <input type="text" placeholder="Nhập họ tên..." />
          </div>
          <div className="d-flex align-items-center phone-dialog">
            <span>Số điện thoại</span>
            <input type="number" placeholder="Nhập số điện thoại..." />
          </div>
          <div className="d-flex align-items-center address-dialog">
            <span>Địa chỉ</span>
            <input type="text" placeholder="Nhập địa chỉ..." />
          </div>
          <label class="label-input">
            <input
              type="radio"
              name="pilih"
              class="input-choose-default"
              id="default-radio"
            />
            <span class="radio-circle"></span> Chọn mặc định
          </label>
        </div>
      </DialogContent>
      <DialogActions className="btn-box">
        <Button className=" btn-abort mr-3" onClick={handClose}>
          Huỷ
        </Button>
        <Button className="btn btn-animation btn-save" onClick={handSubmit}>
          Lưu
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddressDialog;
