import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function PaymentDiaLog({ open, onClose, onSubmit }) {
  const handleCloseDialog = () => {
    onClose();
  };
  const handleSubmitDialog = () => {
    onSubmit();
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Thanh toán</DialogTitle>
      <DialogContent>
        <DialogContentText>Bạn có chắc muốn thanh toán</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="redColor" onClick={handleCloseDialog}>
          Không
        </Button>
        <Button variant="outlined" color="redColor" onClick={handleSubmitDialog}>
          Có
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentDiaLog;
