import React, { useState } from "react";
import "./ManageMyAccount.scss";
import AddIcon from "@mui/icons-material/Add";
import AddressDialog from "~/components/miniComponent/addressDialog/AddressDialog";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";

function ManageMyAccount() {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => setOpenDialog(false);
  return (
    <div className="manage-my-account">
      <div className="my-account">
        <div className="heading">
          <h3 className="title">Hồ Sơ Của Tôi</h3>
          <p className="sub-title">
            Quản lý thông tin hồ sơ để bảo mật tài khoản
          </p>
        </div>
        <div className="content">
          <ul className="list-contents">
            <li className="item-contents">
              <div className="content-left">Tên</div>
              <div className="content-right">
                <div className="text-name">Tên khách hàng</div>
              </div>
            </li>
            <li className="item-contents">
              <div className="content-left">Email</div>
              <div className="content-right">abc1234@gmail.com</div>
            </li>
            <li className="item-contents">
              <div className="content-left">Số điện thoại</div>
              <div className="content-right">
                <div className="text-phone">0300000000</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="add-address">
        <div className="heading">
          <h3 className="title">Địa Chỉ Của Tôi</h3>
          <button
            className="save-account btn btn-animation"
            onClick={() => setOpenDialog(true)}
          >
            <AddIcon />
            Thêm Địa Chỉ
          </button>
        </div>
        <div className="my-address">
          <div className="my-address-left">
            <ul className="list-contents">
              <li className="item-contents">
                <div className="content-left">Tên</div>
                <div className="content-right">
                  <div className="text-name">Tên khách hàng</div>
                </div>
              </li>
              <li className="item-contents">
                <div className="content-left">Số điện thoại</div>
                <div className="content-right">
                  <div className="text-phone">0300000000</div>
                </div>
              </li>

              <li className="item-contents">
                <div className="content-left">Địa chỉ</div>
                <div className="content-right">
                  số nhà, đường, phường, quận, tỉnh
                </div>
              </li>
            </ul>
          </div>
          <div className="my-address-right">
            <label class="label-input">
              <input
                type="radio"
                name="pilih"
                class="input-choose-default"
                id="default-radio"
              />
              <span class="radio-circle"></span> Chọn mặc định
            </label>
            <Button
              color="redColor"
              variant="outlined"
              startIcon={
                <BorderColorIcon
                  color="redColor"
                  className="icon-address icon-edit-address"
                />
              }
            >
              Sửa
            </Button>
            <Button
              color="redColor"
              variant="outlined"
              startIcon={
                <DeleteIcon
                  color="redColor"
                  className="icon-address icon-delete-address"
                />
              }
            >
              Xoá
            </Button>
          </div>
        </div>
        <AddressDialog
          open={openDialog}
          onClose={handleClose}
          onSubmit={handleClose}
        />
      </div>
    </div>
  );
}

export default ManageMyAccount;
