import React from "react";
import "./QuatityButton.scss";

function QuatityButton({ itemId, quantity, handChangeQuantity, handleBlur }) {
  const handChangeQuantitys = (id, quantity) => {
    handChangeQuantity(id, quantity);
  };
  const handleBlurs = (id, quantity) => {
    handleBlur(id, quantity);
  };

  return (
    <div className="content-quantity d-flex">
      <button
        id="decrement"
        onClick={(e) => handChangeQuantitys(itemId, quantity - 1)}
      >
        <i className="bi bi-chevron-left"></i>
      </button>
      <input
        type="number"
        className="form-control"
        value={quantity}
        inputMode="numeric"
        pattern="[0-9]*"
        onChange={(e) => handChangeQuantitys(itemId, parseInt(e.target.value))}
        onBlur={(e) => handleBlurs(itemId, parseInt(e.target.value))}
      />
      <button
        id="increment"
        onClick={(e) => handChangeQuantitys(itemId, quantity + 1)}
      >
        <i className="bi bi-chevron-right"></i>
      </button>
    </div>
  );
}

export default QuatityButton;
