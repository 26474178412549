import React from "react";
import "./MyReviews.scss";
import MyReviewProduct from "../miniComponent/myReviewProduct/MyReviewProduct";
import RatingHoverText from "../miniComponent/ratingHoverText/RatingHoverText";

function MyReviews() {
  return (
    <div className="my-reviews">
      <h3 className="title">Đánh Giá Sản Phẩm</h3>
      <MyReviewProduct />
      <MyReviewProduct />

      {/* review-bottom */}
      <div className="review-bottom">
        <div className="shop-service">
          Dịch vụ shop
          <RatingHoverText />
        </div>
        <div className="bottom-btn">
          <button className=" btn-back btn-outline at-the-same-time">
            Trờ lại
          </button>
          <button className="ml-3 btn-complete  btn btn-animation">
            Hoàn thành
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyReviews;
