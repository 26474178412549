import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "~/components/auth/LoginForm/LoginForm";
import SignUpForm from "~/components/auth/SignUpForm/SignUpForm";

function Login(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (user.userInfo) {
      navigate(-1);
    }
  });

  return <div className="login_form">{props.isLogin ? <LoginForm /> : <SignUpForm />}</div>;
}

export default Login;
