import React from "react";
import OrderItem from "~/components/miniComponent/orderItem/OrderItem";

function Cancelled() {
  return (
    <div>
      <OrderItem />
    </div>
  );
}

export default Cancelled;
