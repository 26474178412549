import React from "react";
import OrderItem from "~/components/miniComponent/orderItem/OrderItem";

function All() {
  return (
    <div>
      <OrderItem />
      <OrderItem />
      <OrderItem />
    </div>
  );
}

export default All;
