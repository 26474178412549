import axios from "axios";
import ApiUrl from "../utils/RewriteApi";

export const getProducts = async ({
  cateId,
  brandIds,
  searchText,
  itemsPerPage,
  page,
  rateScore,
  minPrice,
  maxPrice,
  sortBy,
}) => {
  const params = {
    category_id: cateId,
    brand_id: brandIds,
    searchText: searchText,
    itemsPerPage: itemsPerPage,
    page: page,
    rateScore: rateScore,
    minPrice: minPrice,
    maxPrice: maxPrice,
    sortBy: sortBy || "nameDesc",
  };

  try {
    const res = await axios.get(ApiUrl("/product/get-all-products"), { params });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getProductInFlashSale = async () => {
  try {
    const res = await axios.get(ApiUrl("/product/get-products-flashsale"));
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getProductsByIds = async (productIDs) => {
  try {
    const res = await axios.get(ApiUrl(`/product/get-products-by-ids/${productIDs.join(",")}`));
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};

export const getProductBySlug = async (slug) => {
  try {
    const res = await axios.get(ApiUrl(`/product/get-product-by-slug/${slug}`));
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
