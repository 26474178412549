import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./LoginForm.css";
import avatar from "~/assets/images/avatar/avatar.png";
import LoginWithGoogle from "./LoginWithGoogle";
import { Link } from "react-router-dom";
import { setAssetToken } from "~/redux/slice/UserSlice";
import setAuthToken from "~/utils/setAuthToken";
import { loadUser } from "~/services/LoginService";
import { useDispatch } from "react-redux";

const errorArray = [
  { key: "auth/invalid-email", value: "Định dạng email không đúng" },
  { key: "auth/missing-password", value: "Vui lòng nhập mật khẩu" },
  { key: "auth/user-not-found", value: "Không tồn tại tài khoản này" },
  { key: "auth/wrong-password", value: "Sai email hoặc mật khẩu" },
];

function LoginForm() {
  const dispatch = useDispatch();
  const [account, setAccount] = useState({ email: "", password: "" });
  const [error, setError] = useState();

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, account.email, account.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch(setAssetToken(user.accessToken));
        setAuthToken(user.accessToken);
        loadUser(dispatch);
      })
      .catch((error) => {
        const errorCode = error.code;
        setError(errorArray.find((error) => error.key === errorCode).value);
      });
  };

  const onChangeLoginForm = (event) => {
    setAccount((prevAccount) => ({ ...prevAccount, [event.target.name]: event.target.value }));
  };

  return (
    <>
      <Container component="div" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar src={avatar} sx={{ m: 1, width: 64, height: 64 }}></Avatar>
          <Typography component="h1" variant="h3">
            Đăng Nhập
          </Typography>
          <Box component="form" onSubmit={handleLoginSubmit} noValidate sx={{ mt: 1, mb: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              color="redColor"
              variant="standard"
              onChange={onChangeLoginForm}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="standard"
              color="redColor"
              onChange={onChangeLoginForm}
            />
            <p style={{ color: "red", fontSize: "0.75rem", width: "47%" }}>{error}</p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="redColor"
            >
              Đăng nhập ngay
            </Button>
            <Box component="div" className="list-button" sx={{ mt: 2, mb: 2 }}>
              <LoginWithGoogle />
            </Box>
            <Grid container>
              <Grid item xs>
                <Link to="#" variant="body2">
                  Quên mật khẩu?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/register" variant="body2">
                  {"Chưa có tài khoản? Đăng ký"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default LoginForm;
