import { Button } from "@mui/material";
import React from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useDispatch } from "react-redux";
import { setAssetToken } from "~/redux/slice/UserSlice";
import { loadUser } from "~/services/LoginService";
import setAuthToken from "~/utils/setAuthToken";

function LoginWithGoogle() {
  const dispatch = useDispatch();
  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        dispatch(setAssetToken(user.accessToken));
        setAuthToken(user.accessToken);
        loadUser(dispatch);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log({ code: errorCode, mess: errorMessage, email: email, credential: credential });
        // ...
      });
  };
  return (
    <Button variant="contained" onClick={loginWithGoogle} color="redColor">
      Đăng nhập bằng Google
    </Button>
  );
}

export default LoginWithGoogle;
