import React from "react";
import "./ListProducts.scss";
import Item from "~/components/miniComponent/item/Item";
import cartEmpty from "~/assets/images/cart-empty.png";
import { Pagination } from "@mui/material";

function ListProducts({ listProduct, setFilters }) {
  const handleCurrentPageChange = (event, value) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      page: value,
    }));
  };

  return (
    <div>
      {listProduct.totalProducts !== 0 ? (
        <>
          <div className="list-products-category">
            {listProduct.listProduct?.map((item) => {
              return (
                <div className="product-items" key={item._id}>
                  <Item product={item} />
                </div>
              );
            })}
          </div>
          <div className="product-pagination mt-4 d-flex justify-content-between">
            <span>{`Hiển thị ${listProduct.listProduct?.length} trên tổng số ${listProduct.totalProducts} sản phẩm`}</span>
            <Pagination
              count={listProduct.totalPages || 1}
              color="redColor"
              onChange={handleCurrentPageChange}
            />
          </div>
        </>
      ) : (
        <div className="img-cart-empty">
          <img src={cartEmpty} alt="" />
        </div>
      )}
    </div>
  );
}

export default ListProducts;
