import React, { useEffect, useState } from "react";
import Item from "~/components/miniComponent/item/Item";
import "./WishList.scss";
import Breadcrumb from "~/components/breadcrumb/Breadcrumb";
import BackToTop from "~/components/backToTop/BackToTop";
import { useSelector } from "react-redux";

function WishList() {
  const wishlistArray = useSelector((state) => {
    return state.favorite.favorites;
  });

  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    if (wishlistArray) {
      setWishlist(wishlistArray);
    }
  }, [wishlistArray]);

  return (
    <div>
      <BackToTop />
      <Breadcrumb />
      <div className="wishlist">
        <div className="heading">
          <h1 className="wishlist_title">{`WishList(${wishlist.length})`}</h1>
          <button className="cus-btn button-outline btn-move-all-to-bag">
            Move All To Bag
          </button>
        </div>
        <div className="content-wishlist">
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
        </div>
      </div>
      <div className="just-for-you">
        <div className="heading">
          <h1 className="heading-title">Just For You</h1>
          <button className="cus-btn button-outline btn-see-all">
            See All
          </button>
        </div>
        <div className="content-just-for-you">
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
          <Item />
        </div>
      </div>
    </div>
  );
}

export default WishList;
