import { Container } from "@mui/material";
import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

function Footer() {
  const handSubmitEmail = () => {};

  return (
    <footer className="footer-home">
      <Container>
        <div className="row">
          <div className="col-6 col-md-3 col-lg-3">
            <div className="items">
              <Link to="/" className="link-logo">
                HM's House
              </Link>
              <p className="sub-title">Đặt Mua</p>
              <p className="content">Giảm 10% cho đơn hàng đầu tiên của bạn khi đăng nhập</p>
              <form action="/" className="form-send-email" onSubmit={handSubmitEmail()}>
                <input type="email" name="" id="" placeholder="Nhập Email" />
                <button className="send-icon" type="submit">
                  <i className="bi bi-send"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <div className="items">
              <h5 className="footer-title">Tài khoản</h5>
              <Link to="/myAccount">Tài Khoản Của Bạn</Link>
              <div className="account-links">
                <Link to="/login">Đăng Nhập</Link>/<Link to="/register">Đăng Kí</Link>
              </div>
              <Link to="/cart">Giỏ Hàng</Link>
              <Link to="/wishlist">Yêu Thích</Link>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <div className="items">
              <h5 className="footer-title">Liên kết nhanh</h5>
              <Link to="/">Chính sách bảo mật</Link>
              <Link to="/">Điều khoản sử dụng</Link>
              <Link to="/">Câu hỏi thường gặp</Link>
              <Link to="/">Liên hệ</Link>
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-3">
            <div className="items">
              <h5 className="footer-title">Hỗ Trợ</h5>
              <Link to="https://goo.gl/maps/4FrRKZsj7raAyPMW7">
                04 An Hải 2, An Hải Bắc, Sơn Trà, Đà Nẵng 550000, Việt Nam
              </Link>
              <Link to="mailto:">huyenle96951415@gmail.com</Link>
              <Link to="tel:+">+84: 000-0000-000</Link>
            </div>
          </div>
        </div>
      </Container>
      <div className="copyright">Copyright &copy; 2023 - HM's House</div>
    </footer>
  );
}

export default Footer;
