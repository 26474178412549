import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Product from "~/components/product/Product";
import { setFavorite } from "~/redux/slice/FavoriteSlice";
import { getProductsByIds, getProductBySlug } from "~/services/ProductService";

function ProductDetail() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const wishlistArray = useSelector((state) => {
    return state.favorite.favorites;
  });
  const [product, setProduct] = useState({
    _id: "0",
    productName: "Sản phẩm demo",
    slug: "san-pham-demo",
    image: [
      "https://htmediagroup.vn/wp-content/uploads/2021/09/Anh-san-pham-18.jpg",
      "https://kinhmateyeplus.com/wp-content/uploads/2019/07/58375276_1030717457137478_6594077054918983680_o-e1564303271788.jpg",
      "https://htmediagroup.vn/wp-content/uploads/2021/09/Anh-san-pham-18.jpg",
      "https://kinhmateyeplus.com/wp-content/uploads/2019/07/58375276_1030717457137478_6594077054918983680_o-e1564303271788.jpg",
      "https://htmediagroup.vn/wp-content/uploads/2021/09/Anh-san-pham-18.jpg",
      "https://kinhmateyeplus.com/wp-content/uploads/2019/07/58375276_1030717457137478_6594077054918983680_o-e1564303271788.jpg",
    ],
    description: `<h2>Hello world! </h2>`,
    isWishList: false,
    currentPrice: 100,
    originalPrice: 100,
    rateScore: 4.4,
    rateTimes: 45,
  });

  useEffect(() => {
    const fetchData = async () => {
      const isIdBased = slug.startsWith("id=");
      let res;

      if (isIdBased) {
        const id = slug.slice(3); // Lấy phần sau của slug bỏ qua "id="
        res = await getProductsByIds([id]);
        setProduct(res.products[0]);
      } else {
        res = await getProductBySlug(slug);
        setProduct(res.product);
      }

      const wishlist = localStorage.getItem("wishlist");
      if (wishlist) {
        const wishlistArray = JSON.parse(wishlist);
        const isItemInWishlist = wishlistArray.includes(product._id);
        setProduct((preItem) => ({ ...preItem, isWishList: isItemInWishlist }));
      }
    };

    fetchData();
  }, [slug, product._id]);

  const handleFavoriteClick = () => {
    if (product.isWishList) {
      const updatedWishlist = wishlistArray.filter((productId) => productId !== product._id);
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      dispatch(setFavorite(updatedWishlist));
      setProduct((preItem) => ({ ...preItem, isWishList: false }));
    } else {
      const updatedWishlist = [...wishlistArray, product._id];
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      dispatch(setFavorite(updatedWishlist));
      setProduct((preItem) => ({ ...preItem, isWishList: true }));
    }
  };

  return <Product product={product} handleFavoriteClick={handleFavoriteClick} />;
}

export default ProductDetail;
