import React from "react";
import Breadcrumb from "~/components/breadcrumb/Breadcrumb";
import "./About.scss";

function About() {
  const aboutContent =
    " <h1>Giới thiệu thông tin shop</h1> <b>Nội dung giới thiệu</b>";
  return (
    <div>
      <Breadcrumb />
      <div
        className="about-content"
        dangerouslySetInnerHTML={{ __html: aboutContent }}
      ></div>
    </div>
  );
}

export default About;
