import React, { useState } from "react";
import "./Order.scss";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import All from "~/components/order/all/All";
import ToShip from "~/components/order/toShip/ToShip";
import ToReceive from "~/components/order/toReceive/ToReceive";
import Cancelled from "~/components/order/canclelled/Cancelled";
import ReturnRefund from "~/components/order/returnRefund/ReturnRefund";
import Breadcrumb from "~/components/breadcrumb/Breadcrumb";
import BackToTop from "~/components/backToTop/BackToTop";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Order() {
  // tabs
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="my-order">
      <Breadcrumb />
      <BackToTop />
      <div className="heading text-center">
        <h3 className="title">Đơn Hàng</h3>
        <p className="sub-title">Quản lý đơn hàng của bạn</p>
      </div>
      <div className="tabs-order">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor="secondary"
          className="tabs-list"
        >
          <Tab label="Tất cả" className="tab-header" {...a11yProps(0)} />
          <Tab label="Đang giao" className="tab-header" {...a11yProps(1)} />
          <Tab label="Đã Nhận" className="tab-header" {...a11yProps(2)} />
          <Tab label="Đã Huỷ" className="tab-header" {...a11yProps(3)} />
          <Tab
            label="Trả hàng hoàn tiền"
            className="tab-header"
            {...a11yProps(4)}
          />
        </Tabs>
      </div>
      <div className="tabs-order-content">
        <CustomTabPanel value={value} index={0}>
          <All />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ToShip />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ToReceive />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Cancelled />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <ReturnRefund />
        </CustomTabPanel>
      </div>
    </div>
  );
}

export default Order;
