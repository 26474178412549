import React, { useState } from "react";
import "./Product.scss";
import { Box, IconButton, Rating, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import QuatityButton from "~/components/miniComponent/quatityButton/QuatityButton";
import BigColImg from "../miniComponent/bigColumImg/BigColImg";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReviewRating from "./reviewRating/ReviewRating";

function Product({ product, handleFavoriteClick }) {
  const [imgIndex, setImgIndex] = useState(0);

  const [quantity, setQuantity] = useState(1);

  const handChangeQuantity = (id, quantity) => {
    quantity = Math.min(Math.max(0, quantity), 99);
    if (quantity < 1) setQuantity(1);
    else setQuantity(quantity);
  };
  const handleBlur = (id, quantity) => {
    if (isNaN(quantity)) {
      setQuantity(1);
    }
  };

  return (
    <div className="product ">
      <Breadcrumb productName={product.productName} />
      <div className="product-heading">
        <div className="row">
          <div className="col-md-7 col-lg-7 ">
            <div className="image-gallery">
              <div className="small-img">
                {product.image.map((item, index) => {
                  return (
                    <div key={index} className="img-item" onMouseEnter={() => setImgIndex(index)}>
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              </div>
              <div className="big-img">
                <BigColImg linkImg={product.image[imgIndex]} />
              </div>
            </div>
          </div>
          <div className="col-md-5 col-lg-5">
            <div className="infomation-product">
              <h1 className="title-product">{product.productName}</h1>
              <div className="star-reviews">
                <Box sx={{ display: "flex", color: "#6b6b6b" }}>
                  <Rating name="read-only" value={product.rateScore} precision={0.5} readOnly />
                  {` (${product.rateTimes} đánh giá)`}
                </Box>
                <div className="in-stock">Còn hàng</div>
              </div>
              <Box className="price_container ">
                <Typography variant="subtitle1" className="current_price">
                  {`$ ${product.currentPrice}`}
                </Typography>
                {product.currentPrice === product.originalPrice ? (
                  ""
                ) : (
                  <Typography variant="subtitle2" className="original_price">
                    {`$ ${product.originalPrice}`}
                  </Typography>
                )}
              </Box>
              <hr className="line" />
              {product.options?.map((item, index) => {
                return (
                  <div className="options" key={index}>
                    {item.key + " :"}
                    {item.value.split("$/").map((value, index) => {
                      return (
                        <button className="option-item" key={index}>
                          {value}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
              <div className="quantity-block">
                <QuatityButton
                  itemId={product._id}
                  quantity={quantity}
                  handChangeQuantity={handChangeQuantity}
                  handleBlur={handleBlur}
                />
                <IconButton className={`favorite-button`} onClick={handleFavoriteClick}>
                  {product.isWishList ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                </IconButton>
              </div>
              <div className="add-buy-block">
                <button className="cus-btn button-outline">Thêm Vào Giỏ Hàng</button>
                <button className="cus-btn button-container">Mua Ngay</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="product-description mt-3">
        <Accordion className="descript-block">
          <AccordionSummary
            className="title"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            Mô tả sản phẩm
          </AccordionSummary>
          <AccordionDetails>
            <div
              className="content"
              id="collapseTitleEvaluate"
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="product-rating mt-3">
        <Accordion className="descript-block">
          <AccordionSummary
            className="title"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            {`Đánh giá (${product.rateTimes})`}
          </AccordionSummary>
          <AccordionDetails>
            <div className="content" id="collapseTitleRating">
              <ReviewRating
                rateTimes={product.rateTimes}
                rateScore={product.rateScore}
                productId={product._id}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default Product;
