import React from "react";
import "./PageNotFound.scss";

function PageNotFound() {
  return (
    <div className="page-not-found">
      <h1 className="title-not-found">404</h1>
      <h5 className="sub-title">
        Không tìm thấy trang đã truy cập của bạn. Bạn có thể đi trang chủ.
      </h5>
      <a href="/" type="button" className="btn-back-home">
        Quay về trang chủ
      </a>
    </div>
  );
}

export default PageNotFound;
