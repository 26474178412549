import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import { clearUser, setUserInfo } from "../redux/slice/UserSlice";
import setAuthToken from "~/utils/setAuthToken";

export const loadUser = async (dispath) => {
  try {
    const res = await axios.get(ApiUrl("/auth/login"));
    if (res.data.success) {
      dispath(setUserInfo(res.data.user));
    }
    return res.data;
  } catch (error) {
    dispath(clearUser());
    setAuthToken();
    console.log(error);
  }
};

export const logoutService = (dispath) => {
  dispath(clearUser());
  setAuthToken();
};
