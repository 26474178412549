import React, { useEffect, useState } from "react";
import CateFilter from "~/components/categoryPages/cateFilters/CateFilter";
import CateSidebar from "~/components/categoryPages/cateSidebars/CateSidebar";
import ListProducts from "~/components/categoryPages/listProducts/ListProducts";
import "./Category.scss";
import { useDispatch } from "react-redux";
import { getCategories } from "~/services/CategoryService";
import { useLocation } from "react-router-dom";
import { getProducts } from "~/services/ProductService";
import { getBrands } from "~/services/BrandService";

function Category() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [listCategory, setListCategory] = useState([]);
  const [listBrand, setListBrand] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [currentCateSlug, setCurrentCateSlug] = useState("");
  const [filters, setFilters] = useState({
    cateId: "",
    brandIds: [],
    searchText: "",
    itemsPerPage: 12,
    page: 1,
    rateScore: 0,
    minPrice: 0,
    maxPrice: 10000000,
    sortBy: "nameDesc",
  });

  useEffect(() => {
    setCurrentCateSlug(location.pathname.split("/category/")[1]?.split("&")[0]);
    const fetchData = async () => {
      const resCate = listCategory.length !== 0 ? listCategory : await getCategories(dispatch);
      setListCategory(resCate);
      const resBrand = listBrand?.length !== 0 ? listBrand : await getBrands(dispatch);
      setListBrand(resBrand);
      setFilters((prevFilter) => ({
        ...prevFilter,
        cateId: resCate.find((item) => {
          return item.cateSlug === location.pathname.split("/category/")[1]?.split("&")[0];
        })?._id,
        brandIds: [
          resBrand.find((item) => {
            return (
              item.brandSlug ===
              location.pathname.split("/category/")[1]?.split("&")[1]?.split("brand=")[1]
            );
          }),
        ],
      }));
    };
    fetchData();
    // eslint-disable-next-line
  }, [location]);
  useEffect(() => {
    const fetchData = async () => {
      const resProduct = await getProducts(filters);
      setListProduct(resProduct);
    };
    fetchData();
    // eslint-disable-next-line
  }, [filters]);

  const handleResetFilter = () => {
    setFilters({
      cateId: "",
      brandIds: [],
      searchText: "",
      itemsPerPage: 12,
      page: 1,
      rateScore: 0,
      minPrice: 0,
      maxPrice: 10000000,
      sortBy: "nameDesc",
    });
  };

  return (
    <div>
      <CateFilter
        filters={filters}
        setFilters={setFilters}
        handleResetFilter={handleResetFilter}
        currentCateSlug={currentCateSlug}
        listBrand={listBrand}
      />
      <div className="row">
        <div className="col-lg-3 left-turn">
          <CateSidebar
            category={listCategory}
            filters={filters}
            setFilters={setFilters}
            currentCateSlug={currentCateSlug}
            listBrand={listBrand}
          />
        </div>
        <div className="col-lg-9">
          <ListProducts listProduct={listProduct} setFilters={setFilters} />
        </div>
      </div>
    </div>
  );
}

export default Category;
