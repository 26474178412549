import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import {
  getCategoryStart,
  getCategorySuccess,
  getCategoryFailed,
} from "~/redux/slice/CategorySlice";

export const getCategories = async (dispatch, searchText) => {
  dispatch(getCategoryStart());
  try {
    const res = await axios.get(ApiUrl(`/category/get-category`), {
      params: { searchText },
    });
    dispatch(getCategorySuccess(res.data.listCategory));
    return res.data.listCategory;
  } catch (error) {
    dispatch(getCategoryFailed());
    console.log(error);
  }
};
