import { createSlice } from "@reduxjs/toolkit";

const wishlistFromLocalStorage = localStorage.getItem("wishlist");

const favoriteSlice = createSlice({
  name: "favorite",
  initialState: {
    favorites: wishlistFromLocalStorage ? JSON.parse(wishlistFromLocalStorage) : [],
  },
  reducers: {
    setFavorite: (state, action) => {
      state.favorites = action.payload;
    },
  },
});

export const { setFavorite } = favoriteSlice.actions;
export default favoriteSlice.reducer;
