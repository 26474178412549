import React from "react";
import { Link } from "react-router-dom";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import "./OrderItem.scss";

function OrderItem() {
  return (
    <div className="order-item">
      <ul className="order-list-products">
        <li className="order-item-product">
          <Link to="#" className="link-item-product">
            <div className="img-product">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLWAHRDHEjkepX9IevbplSo5fWo9bHdDADWq-UnE6fjN77jv-4HCCAfFapaOF-uUJRNA&usqp=CAU"
                alt=""
              />
            </div>
            <div className="content-product">
              <h5 className="name-product">
                Tắm gội 2 trong 1 Romano 2in1 650g + Dầu gội sạch gàu 150g
                Romano
              </h5>
              <div className="classify">
                <span className="classify-sub">Phân Loại Hàng:</span>
                <span className="classify-name">Gentlemen</span>
              </div>
              <div className="quantity-product">
                <span className="quantity-product-sub">X</span>
                <span className="product-number">1</span>
              </div>
              <div className="return-date">7 ngày trả hàng</div>
            </div>
          </Link>
        </li>
      </ul>

      <div className="bottom-item">
        <div className="bottom-item-heading">
          <div className="order-status">
            <div className="order-status-block">
              <Link to="#" className="transport">
                <LocalShippingOutlinedIcon className="shipping-icon" /> Đơn hàng
                đang được trung chuyển tới HCM Mega SOC
              </Link>
              <Tooltip title="Cập nhật mới nhất: 12/03/2023" placement="bottom">
                <InfoOutlinedIcon className="info-icon ml-2" />
              </Tooltip>
            </div>
            <div className="status">Đang Giao</div>
          </div>
          <div className="order-price">
            <div className="order-price-sub">
              <VerifiedUserIcon className="order-price-icon" />
              Thành tiền:
            </div>
            <div className="order-price-money">
              <span className="order-money-sub">$</span>
              <div className="order-money">158.000</div>
            </div>
          </div>
        </div>
        <div className="bottom-item-btn">
          <Link
            type="button"
            to="#"
            className="btn btn-animation repurchase-btn"
          >
            Mua Lại
          </Link>
          <Link
            type="button"
            to="#"
            className="btn-outline at-the-same-time contact-the-store-btn"
          >
            Liên Hệ
          </Link>
          <Link
            type="button"
            to="#"
            className="btn-outline at-the-same-time review-btn"
          >
            Đánh giá
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OrderItem;
