import React, { useState } from "react";
import "./CateFilter.scss";
import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CateSidebar from "../cateSidebars/CateSidebar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function CateFilter({
  filters,
  setFilters,
  handleResetFilter,
  currentCateSlug,
  listBrand,
}) {
  const categories = useSelector((state) => {
    return state.category.category;
  });
  const location = useLocation();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleChangeSortBy = (event) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      sortBy: event.target.value,
    }));
  };

  return (
    <div className="category-product d-flex justify-content-between align-items-center mb-3">
      <h3 className="heading-title">
        {categories.categories.find((item) => {
          return item.cateSlug === location.pathname.split("/category/")[1];
        })?.cateName || "Danh sách sản phẩm"}
      </h3>
      <div className="sorted d-flex align-items-center">
        <button
          className="btn btn-animation btn-reset-filter"
          onClick={handleResetFilter}
        >
          Khôi phục
        </button>
        <FormControl>
          <InputLabel color="redColor" id="demo-simple-select-label">
            Sắp Xếp
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filters.sortBy}
            label="Sắp Xếp"
            onChange={handleChangeSortBy}
            sx={{ maxHeight: "2.7rem" }}
            color="redColor"
          >
            <MenuItem value="nameDesc">Tên A → Z</MenuItem>
            <MenuItem value="nameAsc">Tên Z → A</MenuItem>
            <MenuItem value="priceDesc">Giá tăng dần</MenuItem>
            <MenuItem value="priceAsc">Giá giảm dần</MenuItem>
            <MenuItem value="new">Hàng mới</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="menu-mobile-icon" onClick={() => setOpenSidebar(true)}>
        <i className="bi bi-funnel"></i>
      </div>
      <Drawer
        anchor={"right"}
        open={openSidebar}
        onClose={() => setOpenSidebar(false)}
      >
        <Box
          role="presentation"
          onClick={() => setOpenSidebar(false)}
          onKeyDown={() => setOpenSidebar(false)}
        >
          <CateSidebar
            category={categories.categories}
            filters={filters}
            setFilters={setFilters}
            currentCateSlug={currentCateSlug}
            listBrand={listBrand}
          />
        </Box>
      </Drawer>
    </div>
  );
}

export default CateFilter;
