import React, { useEffect, useState } from "react";
import star from "~/assets/images/star.png";
import "./ReviewRating.scss";
import { Avatar, Checkbox, Rating } from "@mui/material";
import { Fancybox } from "@fancyapps/ui";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import DoneIcon from "@mui/icons-material/Done";
import avt from "~/assets/images/avatar/avatar.png";
import formatTimeAgo from "~/utils/FormatTimeAgo";

const convertToShortNumber = (num) => {
  if (num >= 1000) {
    const abbreviatedNumber = (num / 1000).toFixed(1) + "k";
    return abbreviatedNumber;
  }
  return num.toString();
};

function ReviewRating({ rateTimes, rateScore, productId }) {
  const [listRate, setListRate] = useState({
    fiveStar: 0,
    fourStar: 0,
    threeStar: 0,
    twoStar: 0,
    oneStar: 0,
  });
  const [filtertRate, setFilterRate] = useState({
    fiveStar: false,
    fourStar: false,
    threeStar: false,
    twoStar: false,
    oneStar: false,
    latest: false,
    hasImg: false,
  });

  useEffect(() => {
    setListRate({
      fiveStar: 40,
      fourStar: 2,
      threeStar: 2,
      twoStar: 1,
      oneStar: 0,
    });
  }, [filtertRate]);

  Fancybox.bind("[data-fancybox]", {
    hideScrollbar: false,
  });

  const handleFilterClick = (ratingKey) => {
    setFilterRate((preFilter) => ({
      ...preFilter,
      [ratingKey]: !preFilter[ratingKey],
    }));
  };

  return (
    <div className="rating-container">
      {rateTimes === 0 ? (
        <div className="not-rating d-flex justify-content-center flex-column align-items-center">
          <img src={star} width="70px" alt="smile star" className="img-star" />
          <span>Chưa có đánh giá nào cho sản phẩm này</span>
        </div>
      ) : (
        <div className="have-rating">
          <div className="row">
            <div className="col-lg-4">
              <div className="number-rating">
                <div className="rating-summaty d-flex align-items-center">
                  <div className="rating-poin ">{rateScore}</div>
                  <div className="rating-block">
                    <div className="rating-star">
                      <Rating
                        className="icon-star"
                        name="read-only"
                        value={rateScore}
                        precision={0.5}
                        readOnly
                      />
                    </div>
                    <div className="rating-total">{` ${rateTimes} đánh giá`}</div>
                  </div>
                </div>
                <div className="rating-detail">
                  <div className="d-flex rate-content">
                    <Rating name="read-only" className="rate-star" value={5} readOnly />
                    <div className="progress rate-bar">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(listRate.fiveStar / rateTimes) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="rate-time">{convertToShortNumber(listRate.fiveStar)}</div>
                  </div>
                  <div className="d-flex rate-content">
                    <Rating name="read-only" className="rate-star" value={4} readOnly />
                    <div className="progress rate-bar">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(listRate.fourStar / rateTimes) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="rate-time">{convertToShortNumber(listRate.fourStar)}</div>
                  </div>
                  <div className="d-flex rate-content">
                    <Rating name="read-only" className="rate-star" value={3} readOnly />
                    <div className="progress rate-bar">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(listRate.threeStar / rateTimes) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="rate-time">{convertToShortNumber(listRate.threeStar)}</div>
                  </div>
                  <div className="d-flex rate-content">
                    <Rating name="read-only" className="rate-star" value={2} readOnly />
                    <div className="progress rate-bar">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(listRate.twoStar / rateTimes) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="rate-time">{convertToShortNumber(listRate.twoStar)}</div>
                  </div>
                  <div className="d-flex rate-content">
                    <Rating name="read-only" className="rate-star" value={1} readOnly />
                    <div className="progress rate-bar">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${(listRate.oneStar / rateTimes) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="rate-time">{convertToShortNumber(listRate.oneStar)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="all-img-reviews">
                <h5 className="title-img-review">Tất cả hình ảnh (48)</h5>
                <div className="img-review-contents">
                  <a
                    href="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://cdn.nguyenkimmall.com/images/companies/_1/tin-tuc/kinh-nghiem-meo-hay/Ch%E1%BB%A5p%20%E1%BA%A3nh/cach-tao-dang-chup-hinh-dep-7-1.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://cdn.nguyenkimmall.com/images/companies/_1/tin-tuc/kinh-nghiem-meo-hay/Ch%E1%BB%A5p%20%E1%BA%A3nh/cach-tao-dang-chup-hinh-dep-7-1.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://tiemanhsky.com/wp-content/uploads/2021/03/cuc-hoa-mi-2021-1-1024x631.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://tiemanhsky.com/wp-content/uploads/2021/03/cuc-hoa-mi-2021-1-1024x631.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                    className="items"
                    data-fancybox="gallery"
                  >
                    <img
                      src="https://camfashion.vn/wp-content/uploads/2022/01/KKK_6097.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <ul className="list-filter-reviews">
                  <li
                    className={`items ${filtertRate.latest ? "active" : ""}`}
                    onClick={() => handleFilterClick("latest")}
                  >
                    Mới Nhất
                    {filtertRate.latest ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                  <li
                    className={`items ${filtertRate.hasImg ? "active" : ""}`}
                    onClick={() => handleFilterClick("hasImg")}
                  >
                    Có hình ảnh
                    {filtertRate.hasImg ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                  <li
                    className={`items ${filtertRate.fiveStar ? "active" : ""}`}
                    onClick={() => handleFilterClick("fiveStar")}
                  >
                    5
                    <Checkbox
                      icon={<StarBorderIcon />}
                      checkedIcon={<StarIcon sx={{ color: "#faaf00" }} />}
                      checked={filtertRate.fiveStar}
                    />
                    {filtertRate.fiveStar ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                  <li
                    className={`items ${filtertRate.fourStar ? "active" : ""}`}
                    onClick={() => handleFilterClick("fourStar")}
                  >
                    4
                    <Checkbox
                      icon={<StarBorderIcon />}
                      checkedIcon={<StarIcon sx={{ color: "#faaf00" }} />}
                      checked={filtertRate.fourStar}
                    />
                    {filtertRate.fourStar ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                  <li
                    className={`items ${filtertRate.threeStar ? "active" : ""}`}
                    onClick={() => handleFilterClick("threeStar")}
                  >
                    3
                    <Checkbox
                      icon={<StarBorderIcon />}
                      checkedIcon={<StarIcon sx={{ color: "#faaf00" }} />}
                      checked={filtertRate.threeStar}
                    />
                    {filtertRate.threeStar ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                  <li
                    className={`items ${filtertRate.twoStar ? "active" : ""}`}
                    onClick={() => handleFilterClick("twoStar")}
                  >
                    2
                    <Checkbox
                      icon={<StarBorderIcon />}
                      checkedIcon={<StarIcon sx={{ color: "#faaf00" }} />}
                      checked={filtertRate.twoStar}
                    />
                    {filtertRate.twoStar ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                  <li
                    className={`items ${filtertRate.oneStar ? "active" : ""}`}
                    onClick={() => handleFilterClick("oneStar")}
                  >
                    1
                    <Checkbox
                      icon={<StarBorderIcon />}
                      checkedIcon={<StarIcon sx={{ color: "#faaf00" }} />}
                      checked={filtertRate.oneStar}
                    />
                    {filtertRate.oneStar ? <DoneIcon sx={{ fontSize: "1rem" }} /> : ""}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row boder-row">
            <div className="col-lg-4">
              <div className="reviewer">
                <div className="reviewer-heading">
                  <Avatar alt="Remy Sharp" src={"/static/images/avatar/1.jpg" || avt} />
                  <h5 className="reviewer-name">Tên Người Review</h5>
                </div>
                <div className="reviewer-rating d-flex">
                  <strong className="d-block" style={{ width: "5rem" }}>
                    Đánh giá
                  </strong>
                  <Rating name="read-only" value={3} readOnly className="reviewer-star" />
                </div>
                <div className="review-time d-flex">
                  <strong className="d-block" style={{ width: "5rem" }}>
                    Đã mua
                  </strong>
                  <span className="reviewer-right">
                    {formatTimeAgo("2023-05-01T08:57:03.958Z")}
                  </span>
                </div>
                <div className="review-options">
                  <div className="options-items  d-flex">
                    <strong className="d-block" style={{ width: "5rem" }}>
                      Màu
                    </strong>
                    <span className="reviewer-right">Đỏ</span>
                  </div>
                  <div className="options-items  d-flex">
                    <strong className="d-block" style={{ width: "5rem" }}>
                      Size
                    </strong>
                    <span className="reviewer-right">To</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="review-content">
                <div className="review-img-lists">
                  <a
                    href="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                    className="items"
                    data-fancybox="gallery-reviewer"
                  >
                    <img
                      src="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                    className="items"
                    data-fancybox="gallery-reviewer"
                  >
                    <img
                      src="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                    className="items"
                    data-fancybox="gallery-reviewer"
                  >
                    <img
                      src="https://cdn.alongwalk.info/vn/wp-content/uploads/2022/03/25164047/image-cam-nang-song-ao-tron-bo-nhung-cach-chup-hinh-dep-nhat-164817604697763.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <p className="review-text mt-2">
                  Đóng gói đẹp, Máy nguyên hộp, chưa xử dụng, đúng như Chủ shop quảng cáo, đã mua
                  của shop lần 2 , giá rẻ hơn shop khác, tặng 5 sao.
                </p>
                <div className="rep-comment d-flex">
                  <Avatar className="avt-shop" alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                  <div className="rep-comment-content">
                    <div className="rep-heading d-flex">
                      <h5 className="name-shop">HM'S' House</h5>
                      <i className="bi bi-patch-check-fill icon-check"></i>
                    </div>
                    <p className="rep-comment-text">
                      HAPPY PHONE cảm ơn bạn đã đánh giá sản phẩm 5*, chúc bạn có những trải nghiệm
                      sản phẩm thật tuyệt vời. Theo dõi ngay gian hàng HAPPY PHONE để cập nhật thêm
                      nhiều chương trình khuyến mãi hot diễn ra hàng ngày, hàng giờ bạn nha! Nếu bạn
                      thắc mắc về sản phẩm, nhắn tin trực tiếp cho shop hoặc liên hệ tổng đài
                      03600000 để được hỗ trợ nhanh nhất nha.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewRating;
