import { Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import "./Breadcrumb.scss";

const breadcrumbNameMap = {
  "/contact": "Liên Hệ",
  "/about": "Thông Tin",
  "/cart": "Giỏ Hàng",
  "/category": "Danh Mục",
  "/product": "Sản Phẩm",
  "/wishlist": "Yêu Thích",
  "/myOrder": "Đơn Hàng",
};

function Breadcrumb({ productName }) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb" className="my-4">
      <Link underline="hover" to="/" className="link_bread">
        Home
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          productName ? (
            <Typography color="text.primary" key={to}>
              {productName}
            </Typography>
          ) : (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          )
        ) : (
          <Link underline="hover" to={to} key={to} className="link_bread">
            {breadcrumbNameMap[to]}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
