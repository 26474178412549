import React, { useState } from "react";
import "./ChoosePhotoVideoRating.scss";

function ChoosePhotoVideoRating() {
  const [imgArray, setImgArray] = useState([]);

  const handleImageChange = (files, maxLength) => {
    const newImgArray = [...imgArray];

    Array.from(files).forEach((file) => {
      if (!file.type.match("image.*")) {
        return;
      }

      if (newImgArray.length >= maxLength) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const imgData = {
          name: file.name,
          dataUrl: event.target.result,
        };
        newImgArray.push(imgData);
        setImgArray([...newImgArray]); // Update the state here
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageRemove = (fileName) => {
    const newImgArray = imgArray.filter((img) => img.name !== fileName);
    setImgArray([...newImgArray]); // Update the state here
  };

  const maxDisplayImages = 5; // Set the maximum number of displayed images

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleImageChange(files, maxDisplayImages);
  };
  return (
    <div
      className="upload-box"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="upload-btn-box">
        <label className="upload-btn">
          Chọn ảnh
          <input
            type="file"
            multiple // Allow multiple file selection
            data-max_length={maxDisplayImages} // Adjust max length as needed
            className="upload-inputfile"
            onChange={(e) =>
              handleImageChange(e.target.files, maxDisplayImages)
            } // Adjust maxLength as needed
          />
        </label>
      </div>
      <div className="upload-img-wrap">
        {imgArray.slice(0, maxDisplayImages).map((img, index) => (
          <div key={index} className="upload-img-box">
            <div
              style={{ backgroundImage: `url(${img.dataUrl})` }}
              className="img-bg"
            >
              <div
                className="upload-img-close"
                onClick={() => handleImageRemove(img.name)}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChoosePhotoVideoRating;
