import React, { useEffect, useState } from "react";
import "./Item.scss";
import { Box, Card, CardContent, CardMedia, IconButton, Rating, Typography } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFavorite } from "~/redux/slice/FavoriteSlice";
import formatNumber from "~/utils/FormatNumber";
import { addToCart } from "~/redux/slice/CartSlice";

function Item({ product }) {
  const wishlistArray = useSelector((state) => {
    return state.favorite.favorites;
  });
  const dispatch = useDispatch();
  const [item, setItem] = useState({
    _id: "0",
    productName: "Kính mắt",
    productSlug: "kinh-mat",
    image: [
      "https://htmediagroup.vn/wp-content/uploads/2021/09/Anh-san-pham-18.jpg",
      "https://kinhmateyeplus.com/wp-content/uploads/2019/07/58375276_1030717457137478_6594077054918983680_o-e1564303271788.jpg",
    ],
    isWishList: false,
    currentPrice: 100,
    originalPrice: 150,
    rateScore: 4.4,
    rateTimes: 45,
  });
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    setItem((preItem) => (product ? product : preItem));
    if (wishlistArray && wishlistArray.includes(item._id)) {
      setItem((preItem) => ({ ...preItem, isWishList: true }));
    } else {
      setItem((preItem) => ({ ...preItem, isWishList: false }));
    }
  }, [item._id, wishlistArray, product]);

  const handleFavoriteClick = () => {
    if (item.isWishList) {
      const updatedWishlist = wishlistArray.filter((itemId) => itemId !== item._id);
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      dispatch(setFavorite(updatedWishlist));
      setItem((preItem) => ({ ...preItem, isWishList: false }));
    } else {
      const updatedWishlist = [...wishlistArray, item._id];
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      dispatch(setFavorite(updatedWishlist));
      setItem((preItem) => ({ ...preItem, isWishList: true }));
    }
  };

  const handleAddToCartClick = () => {
    const product = {
      product_id: item._id,
      quantity: 1,
    };
    dispatch(addToCart(product));
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card className={`product-card ${isHovered ? "hovered" : ""}`}>
      <Box
        className="image-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link to={`/product/${item.productSlug}`} className="product-link">
          <CardMedia component="img" image={item.image[0]} alt={item.productName} />
        </Link>
        {item.currentPrice === item.originalPrice ? (
          ""
        ) : (
          <Box className="discount-badge">
            <Typography variant="body2" className="discount-badge-text">
              -
              {Math.round(
                ((item.originalPrice - item.currentPrice) / item.originalPrice) * 100 * 10,
              ) / 10}
              %
            </Typography>
          </Box>
        )}
        <IconButton className={`favorite-button`} onClick={handleFavoriteClick}>
          {item.isWishList ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </IconButton>
        <IconButton className={`add-to-cart-button`} onClick={handleAddToCartClick}>
          <AddShoppingCartIcon />
        </IconButton>
        <Box className="add-to-cart-container">
          <button type="button" onClick={() => {}}>
            Mua ngay
          </button>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="subtitle2" component="div" className="item-title">
          <Link to={`/product/${item.productSlug}`} className="product-link">
            {item.productName}
          </Link>
        </Typography>
        <Box className="price_container">
          <Typography variant="subtitle1" className="current_price">
            {`$ ${formatNumber(item.currentPrice)}`}
          </Typography>
          {item.currentPrice === item.originalPrice ? (
            ""
          ) : (
            <Typography variant="subtitle2" className="original_price">
              {`$ ${formatNumber(item.originalPrice)}`}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", color: "#6b6b6b" }}>
          <Rating name="read-only" value={item.rateScore} precision={0.5} readOnly />
          {` (${item.rateTimes})`}
        </Box>
      </CardContent>
    </Card>
  );
}

export default Item;
