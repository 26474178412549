import React from "react";
import "./Contact.scss";
import Breadcrumb from "~/components/breadcrumb/Breadcrumb";
import { TextField } from "@mui/material";

function Contact() {
  const handSubmitContact = () => {};
  return (
    <div>
      <Breadcrumb />
      <div className="contact">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-lg-4 col-md-4">
            <div className="contact_info">
              <div className="call_to_us">
                <div className="title">
                  <i className="bi bi-telephone"></i>Gọi chúng tôi
                </div>
                <p className="information">Vào giờ hành chính các ngày trong tuần</p>
                <a href="tel:+84000000000" className="information">
                  SĐT: +84 000 000 000
                </a>
              </div>
              <hr className="my-4" />
              <div className="write_to_us">
                <div className="title">
                  <i className="bi bi-envelope"></i>Viết thư cho chúng tôi
                </div>
                <p className="information">
                  Chúng tôi sẽ liên hệ lại với bạn sớm nhất trong 3 ngày làm việc
                </p>
                <a className="information" href="mailto:huyenle96951415@gmail.com">
                  Email: huyenle96951415@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="contact_form">
              <form action="" onSubmit={handSubmitContact()}>
                <div className="form-row">
                  <div className="col-md-4 mb-3">
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        name="name"
                        label="Nhập tên"
                        size="small"
                        required
                        color="redColor"
                      />
                      <div className="invalid-feedback">Vui lòng nhập tên</div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <TextField
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      label="Nhập email"
                      name="email"
                      size="small"
                      color="redColor"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="input-group">
                      <TextField
                        type="text"
                        className="form-control"
                        id="validationCustomUsername"
                        name="phonenumber"
                        label="Nhập số điện thoại"
                        aria-describedby="inputGroupPrepend"
                        size="small"
                        required
                        color="redColor"
                      />
                      <div className="invalid-feedback">Vui lòng nhập số điện thoại</div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <TextField
                    id="filled-multiline-flexible"
                    label="Nhập tin nhắn"
                    multiline
                    name="message"
                    rows={6}
                    fullWidth
                    color="redColor"
                  />
                </div>
                <button className="mt-3 btn_send_mess" type="submit">
                  Gửi tin nhắn
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
