import axios from "axios";
import ApiUrl from "../utils/RewriteApi";

export const getDiscountByCode = async (discountCode, productIDs) => {
  try {
    const res = await axios.get(
      ApiUrl(`/discount/get-discount/${discountCode}/${productIDs.join(",")}`),
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
