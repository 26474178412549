import React from "react";
import OrderItem from "~/components/miniComponent/orderItem/OrderItem";

function ToShip() {
  return (
    <div>
      <OrderItem />
      <OrderItem />
    </div>
  );
}

export default ToShip;
