import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Box, Container, Drawer, Tooltip } from "@mui/material";
import SearchForm from "~/components/search/SearchForm";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UserInformation from "~/components/miniComponent/userInfomation/UserInformation";
import ListNavbar from "~/components/miniComponent/listNavbar/ListNavbar";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";

function Header() {
  const user = useSelector((state) => {
    return state.user;
  });

  const wishlistArray = useSelector((state) => {
    return state.favorite.favorites;
  });
  const cartList = useSelector((state) => {
    return state.cart;
  });
  const [favorite, setFavorite] = useState(0);
  const [openNavbar, setOpenNavbar] = useState(false);

  useEffect(() => {
    if (wishlistArray) {
      setFavorite(wishlistArray.length);
    }
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      setIsSticky(currentScroll > 48);
    };

    window.addEventListener("scroll", handleScroll);

    // Hủy bỏ sự kiện khi component bị unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [wishlistArray]);

  // Scroll header
  const [isSticky, setIsSticky] = useState(false);

  return (
    <header className="header-home">
      <div className={`header-top text-center`}>
        <Container maxWidth="lg">
          <div className="text-sale">
            Giảm giá mùa hè cho tất cả các bộ đồ bơi và chuyển phát nhanh miễn phí - GIẢM GIÁ 50%!
            <Link to="/" className="link-sale ml-3">
              Mua ngay
            </Link>
          </div>
        </Container>
      </div>
      <div className={`replacce-header-nav  ${isSticky ? "active" : ""}`}></div>
      <div className={`header-nav ${isSticky ? "active" : ""}`}>
        <Container>
          <div className="nav-content d-flex justify-content-between align-items-center">
            <div className="oen_menu_icon" onClick={() => setOpenNavbar(true)}>
              <MenuIcon />
            </div>
            <Drawer
              className="menu-mobile-show"
              anchor={"left"}
              open={openNavbar}
              onClose={() => setOpenNavbar(false)}
            >
              <Box
                role="presentation"
                onClick={() => setOpenNavbar(false)}
                onKeyDown={() => setOpenNavbar(false)}
              >
                <ListNavbar />
              </Box>
            </Drawer>
            <h1>
              <Link to="/" className="link-logo">
                HM'S' House
              </Link>
            </h1>
            <div className="destop_navbar">
              <ListNavbar />
            </div>

            <div className="nav-left d-flex align-items-center">
              <SearchForm placeholder="Tìm kiếm" />
              <ul className="list-menu-icon d-flex align-items-center">
                <li className="item">
                  <Link to="/wishlist">
                    <Tooltip title="Yêu thích">
                      <IconButton aria-label="wishlist">
                        <Badge
                          badgeContent={favorite}
                          color="redColor"
                          className="customized-badge"
                        >
                          <FavoriteIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>
                </li>
                <li className="item">
                  <Link to="/cart">
                    <Tooltip title="Giỏ hàng">
                      <IconButton aria-label="cart">
                        <Badge
                          badgeContent={cartList.reduce((total, item) => total + item.quantity, 0)}
                          color="redColor"
                          className="customized-badge"
                        >
                          <ShoppingCartIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>
                </li>
              </ul>
              {user.userInfo ? (
                <UserInformation />
              ) : (
                <Link to="/login">
                  <Tooltip title="Đăng nhập">
                    <IconButton aria-label="login">
                      <LoginIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
}

export default Header;
