import axios from "axios";
import ApiUrl from "../utils/RewriteApi";
import { getBrandStart, getBrandSuccess, getBrandFailed } from "../redux/slice/BrandSlice";

export const getBrands = async (dispatch, searchText) => {
  dispatch(getBrandStart());
  try {
    const res = await axios.get(ApiUrl(`/brand/get-brand`), {
      params: { searchText },
    });
    dispatch(getBrandSuccess(res.data.listBrand));
    return res.data.listBrand;
  } catch (error) {
    dispatch(getBrandFailed());
    console.log(error);
  }
};
