import { Container, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import "./App.css";
import "~/configs/firebase-config";
import AppRouter from "./AppRouter";
import Header from "~/views/header/Header";
import Footer from "~/views/footer/Footer";
import { persistor, store } from "./redux/store";
import ScrollToTop from "./ScrollToTop";

const theme = createTheme({
  palette: {
    mode: "light", // đặt chế độ dark mode

    redColor: {
      main: "#DB4444",
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop>
            <div className="App">
              <ThemeProvider theme={theme}>
                <Header />
                <main className="py-4 main-apps">
                  <Container>
                    <AppRouter />
                  </Container>
                </main>
                <Footer />
              </ThemeProvider>
            </div>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
