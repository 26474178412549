import React, { useState } from "react";
import "./BigColImg.scss";

function BigColImg({ linkImg }) {
  const [bgPosition, setBgPosition] = useState("0% 0%");

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBgPosition(`${x}% ${y}%`);
  };
  return (
    <figure
      height="100%"
      width="100%"
      onMouseMove={(e) => handleMouseMove(e)}
      style={{
        backgroundImage: `url(${linkImg})`,
        backgroundPosition: bgPosition,
      }}
    >
      <img src={linkImg} alt="" />
    </figure>
  );
}

export default BigColImg;
