import React, { useEffect, forwardRef } from "react";
import "./CateSidebar.scss";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function valuetext(value) {
  return `${value}$`;
}

function CateSidebar({ category, filters, setFilters, currentCateSlug, listBrand }) {
  useEffect(() => {}, []);

  const categories = useSelector((state) => {
    return state.category.category;
  });

  const handleChangeSilde = (event, newValue) => {
    const [min, max] = newValue;
    setFilters((prevFilter) => ({
      ...prevFilter,
      minPrice: min,
      maxPrice: max,
    }));
  };

  const handleSetRateScore = (value) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      rateScore: value,
    }));
  };
  const handChangePrice = (isMin, value) => {
    const checkValue = (value) => {
      const newValue = parseInt(value);
      // Kiểm tra xem có phải là một số không âm hay không
      if (!isNaN(newValue) && newValue >= 0) {
        return newValue;
      } else {
        return 0;
      }
    };
    if (isMin) {
      setFilters((prevFilter) => ({
        ...prevFilter,
        minPrice: checkValue(value),
      }));
    } else {
      setFilters((prevFilter) => ({
        ...prevFilter,
        maxPrice: checkValue(value),
      }));
    }
  };
  const handleCheckboxBrandChange = (brand) => {
    if (filters.brandIds.includes(brand._id)) {
      setFilters((prevFilter) => ({
        ...prevFilter,
        brandIds: prevFilter.brandIds.filter((id) => id !== brand._id),
      }));
    } else {
      setFilters((prevFilter) => ({
        ...prevFilter,
        brandIds: [...prevFilter.brandIds, brand._id],
      }));
    }
  };

  return (
    <div>
      <div className={`category-sidebar `}>
        <div className="sidebar-heading">
          <i className="bi bi-list-ul"></i>Danh Mục Sản Phẩm
        </div>
        <ul className="list-product-categories">
          <li className={`item-categories ${currentCateSlug === "" ? "active" : ""}`}>
            <Link to={`/category/`}>Tất cả</Link>
          </li>
          {category.map((item) => {
            return categories.isFetching ? (
              <CircularProgress color="inherit" />
            ) : (
              <li
                className={`item-categories ${currentCateSlug === item.cateSlug ? "active" : ""}`}
                key={item?._id}
              >
                <Link to={`/category/${item?.cateSlug}`}>{item.cateName}</Link>
              </li>
            );
          })}
        </ul>
        <div className="price-filter">
          <div className="heading-price-filter d-flex align-items-center justify-content-between mb-2">
            <span>Mức Giá</span>
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => handleChangeSilde(e, [0, 10000000])}
            >
              <HighlightOffIcon />
            </span>
          </div>
          <Box>
            <Slider
              className="price-slider mb-2"
              value={[filters.minPrice, filters.maxPrice]}
              min={0}
              max={100000000}
              onChange={handleChangeSilde}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              color="redColor"
              id="min-price"
              label="Giá thấp nhất"
              name="minprice"
              variant="outlined"
              value={filters.minPrice}
              onChange={(e) => handChangePrice(true, e.target.value)}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
            <TextField
              id="max-price"
              color="redColor"
              name="maxprice"
              label="Giá cao nhất"
              variant="outlined"
              value={filters.maxPrice}
              onChange={(e) => handChangePrice(false, e.target.value)}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
            />
          </Stack>
        </div>
        <div className="sort-by-ratings">
          <div className="heading-price-filter d-flex  align-items-center justify-content-between">
            <span>Đánh Giá</span>
            <span style={{ cursor: "pointer" }} onClick={() => handleSetRateScore(0)}>
              <HighlightOffIcon />
            </span>
          </div>
          <ul className="list-sort-by-ratings">
            <li>
              <div
                onClick={() => handleSetRateScore(5)}
                className={`link-retings  ${filters.rateScore === 5 ? "active" : ""}`}
              >
                <Rating className="mr-2 rating-review" name="disabled" value={5} readOnly />
                <Typography component="legend">5 Sao</Typography>
              </div>
            </li>
            <li>
              <div
                onClick={() => handleSetRateScore(4)}
                className={`link-retings  ${filters.rateScore === 4 ? "active" : ""}`}
              >
                <Rating className="mr-2 rating-review" name="disabled" value={4} readOnly />
                <Typography component="legend">Từ 4 Sao</Typography>
              </div>
            </li>
            <li>
              <div
                onClick={() => handleSetRateScore(3)}
                className={`link-retings  ${filters.rateScore === 3 ? "active" : ""}`}
              >
                <Rating className="mr-2 rating-review" name="disabled" value={3} readOnly />
                <Typography component="legend">Từ 3 Sao</Typography>
              </div>
            </li>
            <li>
              <div
                onClick={() => handleSetRateScore(2)}
                className={`link-retings  ${filters.rateScore === 2 ? "active" : ""}`}
              >
                <Rating className="mr-2 rating-review" name="disabled" value={2} readOnly />
                <Typography component="legend">Từ 2 Sao</Typography>
              </div>
            </li>
            <li>
              <div
                onClick={() => handleSetRateScore(1)}
                className={`link-retings  ${filters.rateScore === 1 ? "active" : ""}`}
              >
                <Rating className="mr-2 rating-review" name="disabled" value={1} readOnly />
                <Typography component="legend">Từ 1 Sao</Typography>
              </div>
            </li>
          </ul>
        </div>
        <div className="product-filter">
          <div className="heading-price-filter d-flex align-items-center justify-content-between">
            <span>Thương Hiệu</span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                setFilters((prevFilter) => ({
                  ...prevFilter,
                  brandIds: [],
                }))
              }
            >
              <HighlightOffIcon />
            </span>
          </div>
          <ul className="list-product-filter">
            {listBrand?.length !== 0
              ? listBrand.map((item) => {
                  return (
                    <li className="item-product-filter" key={item._id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="redColor"
                            checked={filters.brandIds.includes(item._id)}
                            onChange={() => handleCheckboxBrandChange(item)}
                          />
                        }
                        label={item.brandName}
                      />
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </div>
  );
}

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CateSidebar;
