import React, { Suspense, useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import BackToTop from "~/components/backToTop/BackToTop";
import { useDispatch } from "react-redux";
import { getCategories } from "~/services/CategoryService";
import { getBrands } from "~/services/BrandService";
import { getCategoryMapBrand } from "~/services/CategoryMapBrandService";
import "./Home.scss";
import { getProductInFlashSale } from "~/services/ProductService";
const BestSeller = React.lazy(() => import("~/components/HomePage/bestSeller/BestSeller"));
const ListCategories = React.lazy(() => import("~/components/HomePage/category/ListCategories"));
const ExploreOurProducts = React.lazy(() =>
  import("~/components/HomePage/exploreOurProducts/ExploreOurProducts"),
);
const FlashSales = React.lazy(() => import("~/components/HomePage/flashSales/FlashSales"));
const NewArrival = React.lazy(() => import("~/components/HomePage/newArrival/NewArrival"));
const Service = React.lazy(() => import("~/components/service/Service"));
const SideBar = React.lazy(() => import("~/components/sideBar/SideBar"));
const SliderBanner = React.lazy(() => import("~/components/HomePage/Slider-banner/SliderBanner"));

function Home() {
  const dispatch = useDispatch();

  const [listCategory, setListCategory] = useState([]);
  const [listBrand, setListBrand] = useState([]);
  const [listProductFlashsale, setListProductFlashsale] = useState([]);
  const [listCateMapBrand, setListCateMapBrand] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const resCate = await getCategories(dispatch);
      setListCategory(resCate);
      const resBrand = await getBrands(dispatch);
      setListBrand(resBrand);
      const resCateMapBrand = await getCategoryMapBrand();
      setListCateMapBrand(resCateMapBrand);
      const resFlashSale = await getProductInFlashSale();
      setListProductFlashsale(resFlashSale.products);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="home">
      <BackToTop />
      <section className="home-heading">
        <div className="row">
          <div className="col-lg-2">
            <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
              <SideBar
                listCategory={listCategory}
                listBrand={listBrand}
                listCateMapBrand={listCateMapBrand}
              />
            </Suspense>
          </div>
          <div className="col-lg-10">
            <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
              <SliderBanner />
            </Suspense>
          </div>
        </div>
      </section>
      <section className="flash_sales">
        <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
          <FlashSales listProducts={listProductFlashsale} />
        </Suspense>
      </section>
      <section className="list_categories">
        <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
          <ListCategories listCategory={listCategory} />
        </Suspense>
      </section>
      <section className="best_seller">
        <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
          <BestSeller />
        </Suspense>
      </section>
      <section className="explore-our-products">
        <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
          <ExploreOurProducts />
        </Suspense>
      </section>
      <section className="new-arrival">
        <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
          <NewArrival />
        </Suspense>
      </section>
      <section className="service">
        <Suspense fallback={<Skeleton variant="rounded" height={100} />}>
          <Service />
        </Suspense>
      </section>
    </div>
  );
}

export default Home;
